// Constants and types for client/server contracts re: product analytics tracking

import {
  Array,
  Boolean,
  Literal,
  Null,
  Number,
  Record as RRecord,
  Runtype,
  Static,
  String,
  Union,
} from "runtypes";

import {
  DataConnectionTypeLiteral,
  SqlCompletionTypeLiteral,
} from "./dataConnectionTypes";
import {
  CellType,
  DataBrowserTabLiteral,
  NotificationTypeLiteral,
  OrgRole,
  OrgRoleLiteral,
  ProjectRoleLiteral,
  ViewDataOpenedFromLiteral,
} from "./enums";
import { AppFeatureFlagRuntype } from "./FeatureFlag.js";
import { FeatureGateClickedEventData } from "./FeatureGate";
import {
  CellId,
  CollectionId,
  DataConnectionId,
  DataSourceTableId,
  HexId,
  HexVersionId,
  MagicEventId,
  NotificationUserLinkId,
  SemanticDatasetId,
  SemanticProjectId,
} from "./idTypeBrands";
import { ProjectTemplateLiteral } from "./projectTemplate.js";
import { getNormalEnum } from "./runtypeEnums";
import { NoCodeCellTypeLiteral } from "./sql/dataSourceTableConfig.js";
import { Requiredish } from "./utils/typeUtils.js";
import {
  ActionTakenLiteral,
  OnboardingFlowLiteral,
  OnboardingFlowStepLiteral,
} from "./workspaceSetupTypes.js";

/**
 * Amplitude event types that can be tracked via POST request to /track-event
 * Variable names should match the event_type defined in the Amplitude schema
 * The /track-event endpoint will reject any events not defined here
 * and explicitly handled in the server file
 */
export const ClientAnalyticsEventTypeLiteral = Union(
  Literal("FEATURE_GATE_CLICKED"),
  Literal("DATA_CONNECTION_CREATION_FAILED"),
  Literal("DATA_CONNECTION_FORM_OPENED"),
  Literal("AUTOCOMPLETE_ACCEPTED"),
  Literal("AUTOCOMPLETE_TRIGGERED"),
  Literal("PROJECT_EMPTY_STATE_CELL_CLICKED"),
  Literal("FEATURE_FLAG_EVALUATED"),
  Literal("ONBOARDING_STEP_ADVANCED"),
  Literal("VIEW_DATA_OPENED"),
  Literal("PROJECT_TEMPLATE_PREVIEWED"),
  Literal("DEBUG_MODE_OPENED"),
  Literal("NOTIFICATION_INBOX_OPENED"),
  Literal("NOTIFICATION_CLICKED"),
  Literal("PRESENTATION_MODE_OPENED"),
  Literal("KEYBOARD_SHORTCUT_USED"),
  Literal("DATA_BROWSER_DIALOG_OPENED"),
  Literal("DATA_BROWSER_TABLE_SELECTED"),
  Literal("PUBLISH_MODAL_OPENED"),
  Literal("CLIENT_COUNTER_EVENT"),
  Literal("PROJECT_LIST_LAYOUT_UPDATED"),
  Literal("PROJECT_LIST_SORT_UPDATED"),
  Literal("PROJECT_LIST_FILTER_UPDATED"),
  Literal("EXPLORE_CREATED"),
  Literal("EXPLORE_ERRORED"),
  Literal("SEMANTIC_DATASET_SELECTED"),
);
export type ClientAnalyticsEventType = Static<
  typeof ClientAnalyticsEventTypeLiteral
>;
export const ClientAnalyticsEventType = getNormalEnum(
  ClientAnalyticsEventTypeLiteral,
);

const DataBrowserDialogOpenedSourceLiteral = Union(
  Literal("PROJECT_EMPTY_STATE"),
  Literal("EXPLORE_EMPTY_STATE"),
  Literal("EXPLORE_JOIN_DATA"),
  Literal("ACTION_BAR"),
  Literal("ADD_CELL_MENU"),
  Literal("SIDEBAR"),
  Literal("SIDEBAR_PREVIEW"),
  Literal("UNKNOWN"),
  Literal("HOME_DATA_TAB"),
  Literal("MAGIC_FEEDBACK"),
  Literal("MAGIC_MENTION"),
);

export const DataBrowserDialogOpenedSource = {
  ...getNormalEnum(DataBrowserDialogOpenedSourceLiteral),
  ...getNormalEnum(NoCodeCellTypeLiteral),
};
export type DataBrowserDialogOpenedSource = Static<
  typeof DataBrowserDialogOpenedSourceLiteral | typeof NoCodeCellTypeLiteral
>;

export const DataBrowserDialogOpenedEventData = RRecord({
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- no side effects
  dataBrowserOpenedSource: DataBrowserDialogOpenedSourceLiteral.Or(
    NoCodeCellTypeLiteral,
  ),
});
type DataBrowserDialogOpenedEventData = Static<
  typeof DataBrowserDialogOpenedEventData
>;

export const DataBrowserTypeLiteral = Union(
  Literal("PROJECT_DIALOG"),
  Literal("PROJECT_SIDEBAR"),
  Literal("HOME_DATA_TAB"),
  Literal("HOME_DATA_BENTO"),
  Literal("HOME_SIDEBAR"),
  Literal("PROJECT_EMPTY_STATE"),
  Literal("EXPLORE_EMPTY_STATE"),
);

export type DataBrowserType = Static<typeof DataBrowserTypeLiteral>;
export const DataBrowserType = getNormalEnum(DataBrowserTypeLiteral);

const TableActionTakenLiteral = NoCodeCellTypeLiteral.Or(
  Literal(CellType.WRITEBACK),
)
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- no side effects
  .Or(Literal(CellType.SQL))
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- no side effects
  .Or(Literal("SELECT"))
  .Or(Literal("EXPLORE_UI"));

export const DataBrowserTableSelectedEventData = RRecord({
  browserType: DataBrowserTypeLiteral,
  dataSourceTableId: DataSourceTableId.Or(Null),
  dataConnectionId: DataConnectionId.Or(Null),

  tableActionTaken: TableActionTakenLiteral,
  dataBrowserTab: DataBrowserTabLiteral,
});
export type DataBrowserTableSelectedEventData = Static<
  typeof DataBrowserTableSelectedEventData
>;

export const SemanticDatasetSelectedEventData = RRecord({
  tableActionTaken: TableActionTakenLiteral,
  dataBrowserTab: DataBrowserTabLiteral,
  browserType: DataBrowserTypeLiteral,
  semanticDatasetId: SemanticDatasetId,
  semanticProjectId: SemanticProjectId,
});

export type SemanticDatasetSelectedEventData = Static<
  typeof SemanticDatasetSelectedEventData
>;

const ProjectEmptyStateCellClickedTriggeredFromLiteral = Union(
  Literal("FEATURED"),
  Literal("ADD_CELL_MENU"),
  Literal("WORKSPACE_DATA_CONNECTION"),
  Literal("FILE_UPLOAD"),
);

export const ProjectEmptyStateCellClickedTriggeredFrom = getNormalEnum(
  ProjectEmptyStateCellClickedTriggeredFromLiteral,
);

export type ProjectEmptyStateCellClickedTriggeredFrom = Static<
  typeof ProjectEmptyStateCellClickedTriggeredFromLiteral
>;
export const ProjectEmptyStateCellClickedEventData = RRecord({
  cellType: String,
  projectId: HexId,
  triggeredFrom: ProjectEmptyStateCellClickedTriggeredFromLiteral,
  orgRole: OrgRoleLiteral.optional(),
});

export type ProjectEmptyStateCellClickedEventData = Static<
  typeof ProjectEmptyStateCellClickedEventData
>;

// used for the DataConnectionCreationFailed event and the DataConnectionFormOpened event
export const DataConnectionCreationEventData = RRecord({
  connectionType: DataConnectionTypeLiteral,
  projectVersionId: HexVersionId.optional(),
  orgRole: OrgRoleLiteral.optional(),
});
type DataConnectionCreationEventData = Static<
  typeof DataConnectionCreationEventData
>;

export const SqlAutocompleteAcceptedEventData = RRecord({
  autocompleteProviderType: Literal("SQL"),
  suggestionType: SqlCompletionTypeLiteral,
});
type SqlAutocompleteAcceptedEventData = Static<
  typeof SqlAutocompleteAcceptedEventData
>;
export const SqlAutocompleteTriggeredEventData = RRecord({
  autocompleteProviderType: Literal("SQL"),
});
type SqlAutocompleteTriggeredEventData = Static<
  typeof SqlAutocompleteTriggeredEventData
>;

export const KeyboardShortcutUsedEventData = RRecord({
  key: String,
  action: String,
});
type KeyboardShortcutUsedEventData = Static<
  typeof KeyboardShortcutUsedEventData
>;

export const FlagEvaluationTypeLiteral = Union(
  Literal("ENV"),
  Literal("ORG"),
  Literal("USER"),
);

export const FlagEvaluationType = getNormalEnum(FlagEvaluationTypeLiteral);

export const FeatureFlagEvaluatedEventData = RRecord({
  orgRole: OrgRoleLiteral.optional(),
  variation: String,
  featureFlagName: AppFeatureFlagRuntype,
});
type FeatureFlagEvaluatedEventData = Static<
  typeof FeatureFlagEvaluatedEventData
>;

export const NotificationInboxOpenedEventData = RRecord({
  numberUnreadNotifications: Number,
});
type NotificationInboxOpenedEventData = Static<
  typeof NotificationInboxOpenedEventData
>;

export const NotificationClickedEventData = RRecord({
  notificationId: NotificationUserLinkId,
  notificationType: NotificationTypeLiteral,
});
type NotificationClickedEventData = Static<typeof NotificationClickedEventData>;

export const PublishModalOpenedEventData = RRecord({
  projectId: HexId,
  projectVersionId: HexVersionId,
  projectRole: ProjectRoleLiteral.optional(),
});
type PublishModalOpenedEventData = Static<typeof PublishModalOpenedEventData>;

/**
 * Constants and utils for identifying anonymous users in 3rd party
 * analytic services such as Rudderstack and LaunchDarkly.
 * These services typically charge by MAU, where each anonymous user
 * would count as a new MAU - so we want to treat anonymous users for those
 * services as a single user.
 * See https://docs.launchdarkly.com/home/users/anonymous-users
 */
export const ANALYTICS_ANONYMOUS_USER = {
  id: "bb92b229-9d51-491d-a679-9a0734f1e21c",
  email: "anonymous@example.com",
  name: "anonymous",
  stackId: "anonymous",
  orgRole: OrgRole.ANONYMOUS,
};

export const TriggeredFromLiteral = Union(
  Literal("WORKSPACE_SETUP"),
  Literal("SETUP_CHECKLIST"),
  Literal("WORKSPACE_SETTINGS"),
);

export type TriggeredFromType = Static<typeof TriggeredFromLiteral>;

export const TriggeredFromType = getNormalEnum(TriggeredFromLiteral);

export const PublishedAppViewInAppReferrerLiteral = Union(
  Literal("WORKSPACE_SEARCH"),
  Literal("RECENTLY_VIEWED_BENTO"),
  Literal("POPULAR_THIS_WEEK_BENTO"),
  Literal("RECENTLY_PUBLISHED_BENTO"),
  Literal("ALL_PROJECTS"),
  Literal("FAVORITES"),
  Literal("COLLECTION"),
  Literal("DEFLECTION"),
);
export type PublishedAppViewInAppReferrer = Static<
  typeof PublishedAppViewInAppReferrerLiteral
>;
export const PublishedAppViewInAppReferrer = getNormalEnum(
  PublishedAppViewInAppReferrerLiteral,
);

const ProjectFileUploadTriggeredFromLiteral = Union(
  Literal("PROJECT_EMPTY_STATE"),
  Literal("PROJECT_SIDEBAR"),
  Literal("CELL_EMBED"),
  Literal("PROJECT_FILE_UPLOAD_CELL"),
);

export const ProjectTemplatePreviewedEventData = RRecord({
  projectHasCells: Boolean,
  templateName: ProjectTemplateLiteral,
  orgRole: OrgRoleLiteral.optional(),
});
export type ProjectTemplatePreviewedEventData = Static<
  typeof ProjectTemplatePreviewedEventData
>;

export type ProjectFileUploadTriggeredFrom = Static<
  typeof ProjectFileUploadTriggeredFromLiteral
>;
export const ProjectFileUploadTriggeredFrom = getNormalEnum(
  ProjectFileUploadTriggeredFromLiteral,
);

export const OnboardingStepAdvancedEventData = RRecord({
  actionTaken: ActionTakenLiteral,
  flow: OnboardingFlowLiteral,
  step: OnboardingFlowStepLiteral,
  orgRole: OrgRoleLiteral.optional(),
});
export type OnboardingStepAdvancedEventData = Static<
  typeof OnboardingStepAdvancedEventData
>;

export const ViewDataOpenedEventData = RRecord({
  projectId: HexId,
  cellType: String,
  triggeredFrom: ViewDataOpenedFromLiteral,
});
export type ViewDataOpenedEventData = Static<typeof ViewDataOpenedEventData>;

export const DebugModeOpenedEventData = RRecord({
  projectId: HexId,
});
export type DebugModeOpenedEventData = Static<typeof DebugModeOpenedEventData>;

export const PresentationModeOpenedEventData = RRecord({
  projectId: HexId,
});
export type PresentationModeOpenedEventData = Static<
  typeof PresentationModeOpenedEventData
>;

export const ClientCounterEventData = RRecord({
  eventType: String,
});
export type ClientCounterEventData = Static<typeof ClientCounterEventData>;

export const ProjectsPage = Union(
  Literal("PROJECTS"),
  Literal("COLLECTION"),
  Literal("COMPONENTS"),
  Literal("ARCHIVE"),
  Literal("TRASH"),
  Literal("LIBRARY"),
  Literal("EXPLORATIONS"),
);
export type ProjectsPage = Static<typeof ProjectsPage>;

export const ProjectListLayoutUpdatedEventData = RRecord({
  layout: Union(Literal("CARD"), Literal("LIST")),
  collectionId: CollectionId.optional(),
  page: ProjectsPage,
  orgRole: OrgRoleLiteral.optional(),
});
export type ProjectListLayoutUpdatedEventData = Static<
  typeof ProjectListLayoutUpdatedEventData
>;

export const ProjectListSortUpdatedEventData = RRecord({
  layout: Union(Literal("CARD"), Literal("LIST")),
  direction: Union(Literal("ASC"), Literal("DESC")).optional(),
  sortField: String.optional(),
  triggeredFrom: Union(Literal("TABLE_HEADER"), Literal("SORT_DROPDOWN")),
  orgRole: OrgRoleLiteral.optional(),
  updateType: Union(Literal("SORT_FIELD"), Literal("DIRECTION")),
});
export type ProjectListSortUpdatedEventData = Static<
  typeof ProjectListSortUpdatedEventData
>;

export const ProjectListFilterUpdatedEventData = RRecord({
  layout: Union(Literal("CARD"), Literal("LIST")),
  orgRole: OrgRoleLiteral.optional(),
  /**
   * The filter type that is being updated, e.g. "owner", "creator", "workspace", "public", "my-access".
   */
  updateType: String,
  /**
   * The filter value for the updated filter type.
   */
  updateValue: Union(String, Boolean, Null),
  /**
   * All active filter types at the time that updateType was updated.
   */
  activeFilters: Array(String),
});
export type ProjectListFilterUpdatedEventData = Static<
  typeof ProjectListFilterUpdatedEventData
>;

export const ExploreCreatedEventData = RRecord({
  projectId: HexId,
  projectVersionId: HexVersionId,
  magicEventId: MagicEventId,
  // just being used for magic for now but can be extended in the future
  exploreSource: Union(
    Literal("ASK_MAGIC_HOMEPAGE_USER_PROMPT"),
    Literal("ASK_MAGIC_HOMEPAGE_SUGGESTED_PROMPT"),
    Literal("ASK_MAGIC_EXPLORE_EMPTY_STATE_USER_PROMPT"),
    Literal("ASK_MAGIC_EXPLORE_EMPTY_STATE_SUGGESTED_PROMPT"),
    Literal("ASK_MAGIC_EXPLORE_TABLE_SELECTED_USER_PROMPT"),
    Literal("ASK_MAGIC_EXPLORE_TABLE_SELECTED_SUGGESTED_PROMPT"),
  ),
  exploreDatasourceType: Union(
    Literal("DATAFRAME"),
    Literal("SEMANTIC"),
    Literal("DATAWAREHOUSE"),
  ),
  hydrated: Boolean.optional(),
});

export type ExploreCreatedEventData = Static<typeof ExploreCreatedEventData>;

export const ExploreErroredEventData = RRecord({
  hasMagicEvent: Boolean,
  inputType: Union(
    Literal("TABLE"),
    Literal("DATAFRAME"),
    Literal("SEMANTIC_DATASET"),
  ),
  recognizedErrorType: String.optional(),
  orgRole: OrgRoleLiteral.optional(),
  outputId: String,
  cellId: CellId,
});
export type ExploreErroredEventData = Static<typeof ExploreErroredEventData>;

export type ClientAnalyticsEventDataType =
  | FeatureGateClickedEventData
  | DataConnectionCreationEventData
  | SqlAutocompleteAcceptedEventData
  | SqlAutocompleteTriggeredEventData
  | ProjectEmptyStateCellClickedEventData
  | FeatureFlagEvaluatedEventData
  | OnboardingStepAdvancedEventData
  | ViewDataOpenedEventData
  | ProjectTemplatePreviewedEventData
  | DebugModeOpenedEventData
  | NotificationInboxOpenedEventData
  | NotificationClickedEventData
  | KeyboardShortcutUsedEventData
  | PresentationModeOpenedEventData
  | DataBrowserDialogOpenedEventData
  | DataBrowserTableSelectedEventData
  | PublishModalOpenedEventData
  | ClientCounterEventData
  | ProjectListLayoutUpdatedEventData
  | ProjectListSortUpdatedEventData
  | ProjectListFilterUpdatedEventData
  | ExploreCreatedEventData
  | ExploreErroredEventData
  | SemanticDatasetSelectedEventData;

const _eventTypeToData = {
  [ClientAnalyticsEventType.FEATURE_GATE_CLICKED]: FeatureGateClickedEventData,
  [ClientAnalyticsEventType.DATA_CONNECTION_CREATION_FAILED]:
    DataConnectionCreationEventData,
  [ClientAnalyticsEventType.DATA_CONNECTION_FORM_OPENED]:
    DataConnectionCreationEventData,
  [ClientAnalyticsEventType.AUTOCOMPLETE_ACCEPTED]:
    SqlAutocompleteAcceptedEventData,
  [ClientAnalyticsEventType.AUTOCOMPLETE_TRIGGERED]:
    SqlAutocompleteTriggeredEventData,
  [ClientAnalyticsEventType.PROJECT_EMPTY_STATE_CELL_CLICKED]:
    ProjectEmptyStateCellClickedEventData,
  [ClientAnalyticsEventType.FEATURE_FLAG_EVALUATED]:
    FeatureFlagEvaluatedEventData,
  [ClientAnalyticsEventType.ONBOARDING_STEP_ADVANCED]:
    OnboardingStepAdvancedEventData,
  [ClientAnalyticsEventType.VIEW_DATA_OPENED]: ViewDataOpenedEventData,
  [ClientAnalyticsEventType.PROJECT_TEMPLATE_PREVIEWED]:
    ProjectTemplatePreviewedEventData,
  [ClientAnalyticsEventType.DEBUG_MODE_OPENED]: DebugModeOpenedEventData,
  [ClientAnalyticsEventType.NOTIFICATION_INBOX_OPENED]:
    NotificationInboxOpenedEventData,
  [ClientAnalyticsEventType.NOTIFICATION_CLICKED]: NotificationClickedEventData,
  [ClientAnalyticsEventType.KEYBOARD_SHORTCUT_USED]:
    KeyboardShortcutUsedEventData,
  [ClientAnalyticsEventType.PRESENTATION_MODE_OPENED]:
    PresentationModeOpenedEventData,
  [ClientAnalyticsEventType.DATA_BROWSER_DIALOG_OPENED]:
    DataBrowserDialogOpenedEventData,
  [ClientAnalyticsEventType.DATA_BROWSER_TABLE_SELECTED]:
    DataBrowserTableSelectedEventData,
  [ClientAnalyticsEventType.PUBLISH_MODAL_OPENED]: PublishModalOpenedEventData,
  [ClientAnalyticsEventType.CLIENT_COUNTER_EVENT]: ClientCounterEventData,
  [ClientAnalyticsEventType.PROJECT_LIST_LAYOUT_UPDATED]:
    ProjectListLayoutUpdatedEventData,
  [ClientAnalyticsEventType.PROJECT_LIST_SORT_UPDATED]:
    ProjectListSortUpdatedEventData,
  [ClientAnalyticsEventType.PROJECT_LIST_FILTER_UPDATED]:
    ProjectListFilterUpdatedEventData,
  [ClientAnalyticsEventType.EXPLORE_CREATED]: ExploreCreatedEventData,
  [ClientAnalyticsEventType.EXPLORE_ERRORED]: ExploreErroredEventData,
  [ClientAnalyticsEventType.SEMANTIC_DATASET_SELECTED]:
    SemanticDatasetSelectedEventData,
} as const satisfies Record<ClientAnalyticsEventType, Runtype>;

type BatchClientAnalyticsEventMap = {
  [K in keyof typeof _eventTypeToData]: {
    event: K;
    properties: Requiredish<Static<(typeof _eventTypeToData)[K]>>;
  };
};
export type BatchClientAnalyticsEvent =
  BatchClientAnalyticsEventMap[keyof BatchClientAnalyticsEventMap];

type Distribute<T> = T extends { event: string; properties: any }
  ? [T["event"], T["properties"]]
  : never;
export type BatchClientAnalyticsTuples = Distribute<BatchClientAnalyticsEvent>;
